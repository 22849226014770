<template>
  <v-container v-if="vueAddPermissao">
    <v-row>
      <v-col cols="12">
        <PageToolbar
          voltar
          dark
          icon="assignment"
          :title="$tc('permissoes.addpermissao')"
        />
      </v-col>

      <v-col cols="12">
        <v-card class="px-8 pt-12 pb-8">
          <v-form v-model="valid" ref="form">
            <v-container>
              <v-row>
                <v-col cols="12" md="4">
                  <v-card
                    outlined
                    height="40px"
                    class="d-flex justify-space-between align-center"
                  >
                    <v-card-text class="body-1 py-0">{{
                      `Status: ${permissao.status ? "Ativo" : "Inativo"}`
                    }}</v-card-text>
                    <v-switch
                      class="ma-0"
                      hide-details
                      :color="permissao.status ? 'secondary' : 'success'"
                      v-model="permissao.status"
                    ></v-switch>
                  </v-card>
                </v-col>

                <v-col cols="12" md="8">
                  <v-text-field
                    v-model.lazy="permissao.componente"
                    label="Component"
                    :rules="[rules.required]"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="8">
                  <v-text-field
                    v-model="permissao.descricao"
                    :label="$tc('global.descricao')"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-card
                    height="40px"
                    style="overflow: hidden;"
                    class="pa-0"
                    outlined
                  >
                    <v-overflow-btn
                      class="ma-0"
                      height="40px"
                      :items="permissoes"
                      :loading="loading"
                      :disabled="loading"
                      :label="$tc('permissoes.addpai')"
                      dense
                      editable
                      hide-details
                      item-text="descricao"
                      item-value="id"
                      v-model="permissao.permissao_id"
                    ></v-overflow-btn>
                  </v-card>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="permissao.descricao_detalhe"
                    :label="$tc('global.descricaodetalhada')"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>

                <v-row class="px-3" align-content="stretch">
                  <v-col>
                    <v-card height="100%" outlined>
                      <v-subheader>{{ $tc("permissoes.acessos") }}</v-subheader>
                      <v-divider light class="mt-n2"></v-divider>
                      <v-card-text>
                        <v-checkbox
                          v-model="bitWise.menu"
                          class="mt-0"
                          :false-value="0"
                          :true-value="128"
                          label="Menu"
                          hide-details
                        ></v-checkbox>
                      </v-card-text>
                    </v-card>
                  </v-col>

                  <v-col>
                    <v-card height="100%" outlined>
                      <v-subheader>CRUD</v-subheader>
                      <v-divider light class="mt-n2"></v-divider>
                      <v-card-text>
                        <v-checkbox
                          v-model="bitWise.visualizar"
                          class="mt-0"
                          :false-value="0"
                          :true-value="1"
                          :label="$tc('botoes.visualizar')"
                          hide-details
                        ></v-checkbox>
                        <v-checkbox
                          v-model="bitWise.adicionar"
                          class="mt-0"
                          :false-value="0"
                          :true-value="2"
                          :label="$tc('global.add')"
                          hide-details
                        ></v-checkbox>
                        <v-checkbox
                          v-model="bitWise.editar"
                          class="mt-0"
                          :false-value="0"
                          :true-value="4"
                          :label="$tc('botoes.editar')"
                          hide-details
                        ></v-checkbox>
                        <v-checkbox
                          v-model="bitWise.deletar"
                          class="mt-0"
                          :false-value="0"
                          :true-value="8"
                          :label="$tc('botoes.del')"
                          hide-details
                        ></v-checkbox>
                      </v-card-text>
                    </v-card>
                  </v-col>

                  <v-col>
                    <v-card height="100%" outlined>
                      <v-subheader>{{ $tc("botoes.impressao") }}</v-subheader>
                      <v-divider light class="mt-n2"></v-divider>
                      <v-card-text>
                        <v-checkbox
                          v-model="bitWise.imprimir"
                          class="mt-0"
                          :false-value="0"
                          :true-value="16"
                          :label="$tc('botoes.imprimir')"
                          hide-details
                        ></v-checkbox>
                        <v-checkbox
                          v-model="bitWise.exportar"
                          class="mt-0"
                          :false-value="0"
                          :true-value="32"
                          :label="$tc('botoes.exportar')"
                          hide-details
                        ></v-checkbox>
                      </v-card-text>
                    </v-card>
                  </v-col>

                  <v-col>
                    <v-card height="100%" outlined>
                      <v-subheader>{{
                        $tc("permissoes.customizado")
                      }}</v-subheader>
                      <v-divider light class="mt-n2"></v-divider>
                      <v-card-text>
                        <v-checkbox
                          v-model="bitWise.limitar"
                          class="mt-0"
                          :false-value="0"
                          :true-value="64"
                          :label="$tc('botoes.limitar')"
                          hide-details
                        ></v-checkbox>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>

                <v-btn
                  @click.prevent="novaPermissao"
                  fixed
                  right
                  bottom
                  fab
                  dark
                  color="buttons"
                  :class="$vuetify.breakpoint.mdAndUp ? 'mr-12' : ''"
                  :disabled="!valid"
                >
                  <v-icon>save</v-icon>
                </v-btn>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  <NaoAutorizado v-else />
</template>

<script>
import { getPermissoes, postPermissao } from "@/services/api/permissoes.api.js";
import { mapGetters } from "vuex";

export default {
  name: "NovaPermissao",
  data: () => ({
    permissao: {
      status: true,
    },
    bitWise: {
      visualizar: 0,
      adicionar: 0,
      editar: 0,
      deletar: 0,
      imprimir: 0,
      exportar: 0,
      limitar: 0,
      menu: 0,
    },
    permissoes: [],
    show: false,
    valid: false,
    loading: true,
    rules: {
      required: (value) => !!value || "Obrigatorio.",
      min: (v) => v.length >= 3 || "Minimo 4 caracteres",
    },
  }),
  computed: {
    ...mapGetters("Componentes", ["getAccess"]),
    vueAddPermissao() {
      return this.getAccess("vuePermissao", "adicionar");
    },
  },
  methods: {
    backHistory() {
      this.$router.go(-1);
    },
    resetForm() {
      this.$refs.form.reset();
    },
    getPermissoes() {
      this.$Progress.start();
      getPermissoes()
        .then((response) => {
          response.forEach((resp) => {
            this.permissoes.push(resp);
            resp.children.forEach((child) => {
              this.permissoes.push(child);
            });
          });
          this.$Progress.finish();
        })
        .catch((error) => {
          this.$Progress.fail();
          console.error(error);
        });
    },
    novaPermissao() {
      this.permissao.bits =
        this.bitWise.visualizar +
        this.bitWise.adicionar +
        this.bitWise.editar +
        this.bitWise.deletar +
        this.bitWise.imprimir +
        this.bitWise.exportar +
        this.bitWise.limitar +
        this.bitWise.menu;

      postPermissao(this.permissao)
        .then((response) => {
          if (response.status === 201) {
            this.$toast.success(this.$tc("msg.criadosucesso"));
            this.resetForm();
            this.permissao.status = true;
            this.getPermissoes();
          }
        })
        .catch((error) => {
          console.log(error.response);
          if (error.response.status === 422) {
            if (error.response.data.descricao) {
              this.$toast.error(error.response.data.descricao[0]);
            }
            if (error.response.data.status) {
              this.$toast.error(error.response.data.status[0]);
            }
          }
        });
    },
  },
  mounted() {
    this.getPermissoes();
  },
  created() {},
};
</script>

<style scoped lang="scss"></style>
